
.signup{
    margin: 10% auto;
    display: grid;
    width: 25%;
    padding: 25px;
    min-width: 500px;
    background: white;
    border-radius: 2px;
    color: rgb(98, 51, 226);
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
    grid-template-areas: 
    'title'
    'email'
    'password'
    'password-confirmation'
    'submit';

}

.signup__title{
    grid-area: title;
    justify-self: center;
    color: rgb(115, 10, 235);
    margin: 0;

}
.signup__title h1{
    margin: 10px;
}
.signup__title span{
    display: flex;
    margin: 0px 40px;
}

.signup__email{
    grid-area: email;
    margin: 10px 0;
}


/* just to line up the label with the svg */
.signup__email label{
    position: relative;
    bottom: 5px;
}
.signup__email input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}

/* shine on click */
.signup__email:focus-within{
    color:rgb(163, 50, 255)
}

.signup__email input:focus{
    border: rgb(163, 50, 255) solid 2px;
}

.signup__password{
    grid-area: password;
    margin-top: 20px;
}

.signup__password input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}

.signup__password:focus-within{
    color: rgb(163, 50, 255);
}
.signup__password input:focus{
    border: rgb(163, 50, 255) solid 2px;
}


.signup__password-confirmation input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}
/* Password glow on click */
.signup__password-confirmation:focus-within{
    color: rgb(163, 50, 255);
}
.signup__password-confirmation input:focus{
    border: rgb(163, 50, 255) solid 2px;
}
.signup__password-confirmation{
    grid-area: password-confirmation;
    margin-top: 20px;
}


/* Submit button */
.signup__submit{
    grid-area: submit;
    padding: 20px 0;
}

.signup__submit button{
    width: 100%;
    border-radius: 5px;
    border: none;
    height: 50px;
    background: linear-gradient(to right, #4776e6, #8e54e9);
    color: white;

}

/* Button glow */
.signup__submit:hover button{
    background: linear-gradient(to right, #00f260, #0575e6);
    cursor:pointer;
}



/* Make full width and have space on mobile */

@media(max-width: 500px){
    .signup{
        margin: 20% auto;
        display: grid;
        width: 100%;
        border: black solid 2px;
        padding: 25px;
        min-width: 325px;
    }
    
}
.error{
    color: red;
    border: red solid 2px;
    width: 100%;
    height: 100%;
    padding: 10px;
}