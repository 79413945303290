
.single-entry{
    display:grid;
    grid-template-columns: 6fr 1fr;
    grid-template-areas: 'item1 item2';
    justify-items: flex-start;
    grid-auto-flow: column;
    border-radius: 30px;
    padding: 0px 5px;
}

@media(max-width: 500px){
    .single-entry{
        display:grid;
        grid-template-columns: 5fr 2fr;
        grid-template-areas: 'item1 item2';
        justify-items: flex-start;
        grid-auto-flow: column;
        border-radius: 30px;
        padding: 0px 5px;
    
    }
}

.single-entry:hover, .single-entry:active, .single-entry:hover a, .single-entry:hover svg{
    background-color:rgb(153, 0, 255);
    color: white;
    text-decoration: underline;

}
.content{
    grid-area: item1;
    width: 100%;
}

.content__link{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media(max-width: 500px){
    .content{
        min-width: 300px;
    }
    .content__link{
        display: inline-block;
        width:200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
}

.trash{
    grid-area: item2;
    justify-self: end;
}

.rename-bar {
    width: 80%;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom:2px solid rgb(49, 184, 246);
    background: inherit;
    color: inherit;
    font: inherit;
}

.rename-bar:hover{
    color: inherit;
}


.toggle{
    width: 100%;
    position: relative;
}

.test{
    width: 100%;
}