

body{
    height: 100%;
}
.container{
    width: 100%;
 } 

.isEmpty{
    font-size: 2rem;
    text-align: center;
}
.back{
    color: white !important;
}

.file-name{
    font-size: 2rem;
    color: white;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media(max-width: 500px){
    .file-name{
        font-size: 1.3rem;
        text-overflow: clip;
    }
}
.file-name svg{
    color: white;

}
.file-name input{
    font: inherit;
    color: inherit;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: rgb(39, 255, 208);
    width: 90%;
    background: inherit;
}
.add-entry{
    display: flex;
    font-size: 1.4rem;
    padding-top: 20px;
    border: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(98, 41, 255);
    color: rgb(202, 202, 202);
    width: 100%;
    height: 70px;
    justify-content: left;
    padding-left: 50px;
    margin:auto;
}

.add-entry__text{
    position: relative;
    padding-left: 20px;
    bottom: 1.5px;
}
.add-entry:hover, .add-entry:active{
    background-color: rgb(99, 89, 241);
    color: white;
    cursor: pointer;
}


.floating-button{
    width: 56px;
    height: 56px;
    background: rgb(39, 255, 208);
    border-radius: 50%;
    position: fixed;
    left:85%;
    top: 90%;
}


.floating-button__icon{
    position: absolute;
    top: 25%;
    left: 25%;
    margin: auto;
    height: 50%;
    width:50%;
}

.floating-button:hover, .floating-button:hover svg{
    background: rgb(0, 140, 255);
    color: white;
    cursor: pointer;
}

@media(max-width: 500px){
    .floating-button{
        width: 56px;
        height: 56px;
        background: rgb(39, 255, 208);
        border-radius: 50%;
        position: fixed;
        left: 80%;
        bottom: 5%;
    }
}
@media(max-width: 500px){
    .container{
        width: 100%;
        margin-bottom: 200px;
    }
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion{
    display: flex;
    flex-direction: column;
  }

/* Style the buttons that are used to open and close the accordion panel */
.accordion__header{


  display:grid;

  grid-auto-flow: row;
  grid-template-areas: 'item1 item2 item3 item4';
  grid-template-columns: 20px .5fr 8.5fr 1fr;
  background-color: rgb(153, 0, 255);
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 18px;
  border: none;
  outline: none;
}


@media(max-width: 500px){
  .accordion__header{


    display:grid;
  
    grid-auto-flow: row;
    grid-template-areas: 'item1 item2 item3 item4';
    grid-template-columns: 15px .5fr 8.5fr 1fr;
    background-color: rgb(153, 0, 255);
    align-items: center;
    color: white;
    cursor: pointer;
    padding: 18px;
    /* display: flex; */
    /* align-items: center; */
    border: none;
    outline: none;
    /* transition: background-color 0.6s ease; */
  }
}

/* Style to rotate icon when state is active */
.rotate {
  grid-area: item1;
  transform: rotate(90deg);
  
}

.accordion__header__edit{
  grid-area: item2;
  background-color: inherit;
}


/* Style the accordion content title */
.accordion__header__text {
  grid-area: item3;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordion__header__text input{
  width: 100%;
  display: inline-block;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-bottom: rgb(39, 255, 208) solid 2px;
  background-color: inherit;
  font: inherit;
  color: inherit;
}
.accordion__header__delete{
    grid-area: item4;
    background-color: inherit;
    justify-self: center;
}



/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.accordion__header:hover, .active {
  background-color: rgb(133, 39, 255);
}

.accordion__header:hover svg{
  color: white;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: rgb(36, 36, 36);
  color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
  padding-left: 25px;
  padding-right: 25px;
  word-wrap: break-word

}



.editor{
  background: black;
  word-wrap: break-word
}
