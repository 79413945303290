.container{
    width: 70%;
    height: 100%;
    margin: auto;
    background: rgba(0, 0, 0, 0.5);
    min-width: 475px;
}

@media(max-width: 500px){
    .container{
        width: 100%;
    }
}
.login{
    margin: 10% auto ;
    display: grid;
    width: 25%;
    padding: 25px;
    min-width: 475px;
    background: rgb(255, 255, 255);
    border-radius: 2px;
    color: rgb(98, 51, 226);

    grid-template-areas: 
    'title'
    'email'
    'password'
    'submit'
    'signup'
    'forgot';
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
}


.login__title{
    grid-area: title;
    justify-self: center;
    color: rgb(115, 10, 235);
}


.login__email{
    grid-area: email;
    margin: 10px 0;
}

.login__email:focus-within{
    color: rgb(163, 50, 255);
}

.login__email label{
    position: relative;
    bottom: 5px;
}
.login__email input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}

.login__email input:focus{
    border: rgb(163, 50, 255) solid 2px;
}

.login__password{
    grid-area: password;
    margin-top: 20px;
}
.login__password label{
    position: relative;
    bottom: 3px;
}

.login__password input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}

.login__password:focus-within{
    color: rgb(163, 50, 255);
}
.login__password input:focus{
    border: rgb(163, 50, 255) solid 2px;
}

.login__submit{
    grid-area: submit;
    padding: 20px 0;
}
.login__submit button{
    width: 100%;
    border-radius: 5px;
    border: none;
    height: 50px;
    background: linear-gradient(to right, #4776e6, #8e54e9);
    color: white;

}

.login__submit:hover button{
    background: linear-gradient(to right, #00f260, #0575e6);
    cursor: pointer;
}
.login__signup{
    grid-area: signup;
    justify-self: center;

}



.login__forgot{
    grid-area: forgot;
    justify-self: center;
}



@media(max-width: 1450px){
    .container{
        width: 100%;
    }
}

@media(max-width: 500px){
    .login{
        display: grid;
        width: 100%;
        border: black solid 2px;
        padding: 25px;
        min-width: 300px;
        left: 0%;
    }
    .container{
        min-width: 300px;
    }
    
}


.error{
    color: red;
    border: red solid 2px;
    width: 100%;
    height: 100%;
    padding: 10px;
}