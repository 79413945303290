.spacer{
    margin-bottom: 25px;
}

.side-nav__closed{
    width: 100%;
    height: 65px;
    background: rgb(154, 30, 255);
    position: fixed;
    z-index: 300;
    top:0;
    right:0;
    /* margin-bottom: 500px; */
}

.side-nav__opened{
    position: fixed;
    height: 100%;
    z-index: 400;
    top: 0;
    right: 0;
}

.side-nav__toggle{
    position: fixed;
    z-index: 700;
    top: 0;
    left: 0;
}


.side-nav__content{
    display:flex;
    position: fixed;
    flex-direction: column;
    height: 100vh;
    width: 300px;
    background: rgb(154, 30, 255);
    justify-content: normal;
    z-index: 600;
    top: 0;
    left: 0;
    padding-top: 80px;
}

.side-nav__content a{
    color: white;
    text-decoration: none;
    width: 100%;
    font-size: 2rem;
    z-index: 600;
    padding-bottom: 2px;
    display: flex;
    outline: none;
}

.side-nav__content a:hover{
    background: rgb(36, 147, 238);

}

.side-nav__content--selected{
    display: flex;
    width: 100%;
    background: rgb(130, 36, 238);
}
.side-nav__content svg{
    width: 20px;
    margin: 0 10px;
}

.side-nav__content__home{
    width: 100%;
}
.side-nav__content__home span{
    position: relative;
    bottom: 1px;
}
.side-nav__content__contact span{
    position: relative;
    bottom: 2px;
}
.side-nav__content__login span{
    position: relative;
    bottom: 1px;
}


.logo a{
    text-decoration: none;
    color: inherit;
}