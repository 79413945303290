
.top-nav{
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 1fr;
    background-color: rgb(154, 30, 255);
    height: 100%;
    max-height: 65px;
    position:relative;
}   



/* logo */
.top-nav__left{
    grid-area: left;
    display: flex;
    justify-content: flex-start;
}
.top-nav__logo{
    display: grid;
    align-content: center;
    margin-left: 30px;
    height: 100%;
    max-height: 65px;
}

.top-nav__logo h1{
    max-height: 65px;
    height: 100%;
}



/* Nav options */
.top-nav__right{
    grid-area: right;
    display: flex; 
    justify-content: space-evenly;
}



/* Undo a tag styles */
.top-nav a{
    text-decoration: none;
    color: white;
    outline: none;
}




.top-nav__home{
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100%;
    max-height: 65px;
    width: 15%;
    position: relative;
}

.top-nav__home a{
    padding-right: 4px;
}

.top-nav__home:hover{
    border-bottom: 5px solid rgb(0, 255, 179);
    color:rgb(0, 255, 179);
    padding-top: 5px;
}

.top-nav__home a:hover{
    color:rgb(0, 255, 179)
}


.top-nav__contact{
    position: relative;
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100%;
    max-height: 65px;
    width: 15%;
}


.top-nav__contact:hover{
    border-bottom: 5px solid rgb(0, 255, 179);
    color:rgb(0, 255, 179);
    padding-top: 5px;
}

.top-nav__contact a:hover{
    color:rgb(0, 255, 179)
}
.top-nav__login{
    position: relative;
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100%;
    max-height: 65px;
    width: 30%;
    background-color: rgb(57, 54, 248);
}


.top-nav__login:hover{
    background: rgb(89, 0, 255);
    border-bottom: 5px solid rgb(0, 255, 179);
    padding-top: 5px;
}

.top-nav__login a:hover{
    color:rgb(0, 255, 179)
}

/* active link react router */
.top-nav--selected{
     display: grid; 
    justify-content: center;
    align-content: center; 
    position: absolute;
    border-bottom: 5px solid rgb(91, 129, 255);
    padding-top: 5px;
    width: 100%;
    height: 100%;
}

/* Side Nav content */
.side-nav{
    position:fixed;
    display: flex;
    flex-direction: column ;
    background: rgb(125, 53, 241);
    width: 15%;
    height: 100%;
    z-index: 100;
    min-width: 300px;
    padding: 50px 0 0 0;
}


/* for hamburger icon */
.hamburger-icon{
    background: rgb(107, 20, 220);
    border-radius: 10px;
}
.hamburger-icon svg{
    color: white;
}

.contact a{
    color: white;
}
