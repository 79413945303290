.side-nav{
    position:fixed;
    background: rgb(125, 53, 241);
    width: 15%;
    height: 100%;
    bottom: 0px;
    z-index: 1500;
    min-width: 200px;
    padding: 50px 0 0 0;
    font-size: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left:0;
    top:0;
}


.side-nav__file{
    width: 100%;
}
.side-nav__file:hover{
    background: rgb(36, 147, 238);
}

.side-nav__user{
    width: 100%;
}

.side-nav__user:hover{
    background: rgb(36, 147, 238);
}


.side-nav__envelop{
    width: 100%;
}


.side-nav__envelope:hover{
    background: rgb(36, 147, 238);
}

.side-nav a{
    text-decoration: none;
    color: white;
    outline: none;
    padding-left: 5%;
}

.side-nav__logout{
    padding-left: 5%;
}
.side-nav--selected{
    display: flex; 
    width: 100%;
    background: rgb(130, 36, 238);
}



.side-nav svg{
    width: 20px;
    margin: 0px 10px;
}




.side-nav__file:hover{
    background: rgb(36, 147, 238);
}

.side-nav__logout{
    display:flex;
}
.side-nav__logout__button{
    display: flex;
    color: white;
    font-size: 2rem;
    width: 100%;
    height: 50px;
    background: none;
    padding: 0;
    border: none;
}
.side-nav__logout:hover{
    background: rgb(36, 147, 238);
    cursor: pointer;
}
.side-nav__logout button{
    color:white;
    
}

.side-nav__logout button{
    cursor: pointer;
}
.side-nav__logout svg{
    width: 30px;
    margin-bottom: 5px;
}

/* for hamburger icon */

.hamburger{
    position: fixed;
    z-index: 2000;
    top: 0;
    left:0;
}
.hamburger-icon{
    background: rgb(125, 53, 241);
    border-radius: 5px;
    color: rgb(220, 220, 220);
}
.hamburger-icon svg{
    color: white;
}