html{
    height: 100%;
}
body{
    height: 100%;
}
.container{
    /* width: 100%; */
    width: 70%;
    margin: auto;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    min-width: 250px;


}
.contact{
    width: 20%;
    min-width: 250px;
    height: 20%;
    margin: auto;
    padding-top: 10%;
    color: white;
    text-align: center;
}
.container a{
    color:white
}


@media(max-width: 1450px){
    .container{
        width: 100%;
    }
}

@media(max-width: 1000px){   
    .contact{
        width: 100%;
        padding-top: 20%;
    }
}