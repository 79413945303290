

.back{
    width: 50px;
    color: rgb(130, 38, 252);
    border-radius: 50%;
    position: relative;
    top: 125px;
    left: 20%;

}

.back:hover{
    cursor: pointer;
    
}

.back svg:hover{
    color:rgb(165, 55, 255)
}
