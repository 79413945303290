



.file-list{
    list-style: none;
    display: flex;
    flex-direction:column ;
    /* border: 2px solid black; */
    width: 40%;
    margin: 100px auto;
    min-width: 598px;
    position: relative;

}

.file-list a{
    /* color: rgb(177, 70, 248); */
    color: white;
    text-decoration: none;
}
.file-list svg{
    /* color: rgb(153, 0, 255) */
   /* color: rgb(20, 20, 20) */
   /* color: rgb(206, 206, 206); */
   color: rgb(194, 194, 194);

}

@media(max-width: 500px){
    .file-list{
        list-style: none;
        display: flex;
        flex-direction:column ;
        /* border: 2px solid black; */
        width: 100%;
        margin: 100px 0;
        min-width: 300px;
    }
}

/* .file-list__items{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid black;

} */


/* .file-list__items:hover, .file-list__items:active, .file-list__items:focus{
    background: rgb(153, 0, 255);
} */
/* .file-list__items--trash{
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
} */
.add-button{
    display: flex;
    width: 100%;
    height: 50px;
    background-color: rgb(133, 39, 255);
    color: white;
    border: none;
    justify-content: left;
    padding-left: 5px;
    align-items: center;
    /* margin-left: 35%; */
    border-radius: 30px;
    cursor: pointer;
}

.add-button svg{
    color:rgb(226, 226, 226);
}

.add-button:hover, .add-button:active, .add-button:hover svg{

    color: white;
    background-color:rgb(153, 0, 255);
    text-decoration: underline;
}

.search{
    color: white;
    background-color: rgb(39, 39, 39);
    border-radius: 100px;
    padding: 5px;
    margin: 20px 0px;
    display: flex;
    align-content: center;
    border: solid 3px rgb(48, 14, 134);
    
}

.search:hover{
    border: solid 3px rgb(153, 0, 255);
}
.search__bar{
    width: 80%;
    outline: none;
    border: none;
    background: inherit;
    color: inherit;
    padding-left: 10px
}

.search__icon{
    height: 40px;
    width: 40px;
    border: 2px;
    border-radius: 50%;
    /* padding: 20px; */
    background: rgb(153, 0, 255);
    position: relative;
    display: flex;
    justify-content: center;
}

.search__icon img{
    height: 50%;
    position: absolute;
    top: 10px;
    color: white;
}

.search__icon:hover{
    cursor:pointer;
    background-color:rgb(181, 71, 255);
}

.floating-button{

    width: 56px;
    height: 56px;
    background: rgb(39, 255, 208);
    border-radius: 50%;
    position: fixed;
    left:85%;
    top: 90%;
}
@media(max-width: 500px){
    .floating-button{

        width: 56px;
        height: 56px;
        background: rgb(39, 255, 208);
        border-radius: 50%;
        position: fixed;
        left: 80%;
        bottom: 5%;
    }
}
.floating-button__icon{
    position: absolute;
    top: 25%;
    left: 25%;
    margin: auto;
    height: 50%;
    width:50%;
}

.floating-button:hover, .floating-button:hover svg{
    background: rgb(0, 140, 255);
    color: white;
    cursor: pointer;
}

.file-list__array{
    /* position: relative; */
}
.shade{
    height:100%;
    width: 100%;
    opacity: 0.5;
    background: black;
    /* border-top: 3px solid #ccc;
    border-bottom: 3px solid #ccc; */
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    z-index: -200;
}