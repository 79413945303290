@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion{
    display: flex;
    flex-direction: column;
  }

/* Style the buttons that are used to open and close the accordion panel */
.accordion__header{
  display:grid;
  grid-auto-flow: row;
  grid-template-areas: 'item1 item2 item3 item4';
  grid-template-columns: 20px .5fr 8.5fr 1fr;
  background-color: rgb(153, 0, 255);
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 18px;
  border: none;
  outline: none;
  position: sticky;
  top: 0px;
  z-index: 1000;
}


@media(max-width: 500px){
  .accordion__header{


    display:grid;
  
    grid-auto-flow: row;
    grid-template-areas: 'item1 item2 item3 item4';
    grid-template-columns: 15px .5fr 8.5fr 1fr;
    background-color: rgb(153, 0, 255);
    align-items: center;
    color: white;
    cursor: pointer;
    padding: 18px;
    border: none;
    outline: none;
  }
}

/* Style to rotate icon when state is active */
.rotate {
  grid-area: item1;
  transform: rotate(90deg);
  
}

.accordion__header__edit{
  grid-area: item2;
  background-color: inherit;
}


/* Style the accordion content title */
.accordion__header__text {
  grid-area: item3;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordion__header__text input{
  width: 100%;
  display: inline-block;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-bottom: rgb(39, 255, 208) solid 2px;
  background-color: inherit;
  font: inherit;
  color: inherit;
}
.accordion__header__delete{
    grid-area: item4;
    background-color: inherit;
    justify-self: center;
}



/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.accordion__header:hover, .active {
  background-color: rgb(133, 39, 255);
}

.accordion__header:hover svg{
  color: white;
}

/* Style the accordion chevron icon */
.accordion__icon {
  /* margin-left: auto;
  transition: transform 0.6s ease; */
}


/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: rgb(36, 36, 36);
  color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
  padding-left: 25px;
  padding-right: 25px;
  word-wrap: break-word
}



.editor{
  background: black;
  word-wrap: break-word
}