
body{
    height:100%;
    width: 100%;
    /* background-size: 100%;
    background: url(/images/moon-2048727_1920.jpg) no-repeat center center fixed;  */
    /* background-color: rgb(43, 37, 37); */
}

.presentation{
    display: grid;
    grid-template-areas: 
    'navbar navbar navbar navbar'
    'top top top top'
    'demo demo demo demo';
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-column: auto;
    width: 70%;
    margin: auto;
}


.navbar{
    grid-area: navbar;
}

.top{
    grid-area: top;
    display: flex;
    flex-wrap: wrap;
    margin: 10% 0;
}
.video{
    grid-area: video;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%; 
    position: relative;
    padding-bottom: 56.25%;
    z-index: 100;
}
.video iframe{
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.reasons{
    grid-area: reasons;
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: center;
    min-width: 300px;
}

.reasons__item{
    position: relative;
}

.reasons__item__title{
    font-size: 1.75rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.reason__item__description{
    text-align: center;
    font-size: 1.4rem;
}

.demo{
    grid-area: demo;
}

.demo h1{
    text-align: center;
}


.shade{
    height:100%;
    margin-bottom: 90px;
    width: 100%;
    opacity: 0.5;
    background: black;
    border-radius: 3px;
    margin-top: 5.0em;
    position: relative;
    bottom: 750px;
    bottom: 100%;
    z-index: -200;
}

@media(max-width: 500px){
    .presentation{
        width: 100%;
    }
    .video{
        width: 100%;
    }
}


@media(max-width: 1450px){
    .presentation{
        width: 100%;
    }
}

@media(max-width: 1000px){

    .video{
        width: 100%;
    }
    .reasons{
        width: 100%;
    }

}
