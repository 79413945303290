body{
    /* background-color: rgb(28, 28, 28); */
    height: 100%;
}
.container{
    width: 70%;
    margin: 5% 0;
    position: relative; 
    left: 20%;
    

}

@media(max-width: 500px){
    .container{
        left: 0;
    }
}

.isEmpty{
    font-size: 2rem;
}
.back{
    color: white !important;
}



.file-name{
    font-size: 2rem;
    color: white;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media(max-width: 500px){
    .file-name{
        font-size: 1.3rem;
        text-overflow: clip;
    }
}
.file-name svg{
    color: white;
}
.file-name input{
    font: inherit;
    color: inherit;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: rgb(39, 255, 208);
    width: 90%;
    background: inherit;
}
.add-entry{
    display: flex;
    font-size: 1.4rem;
    padding-top: 20px;
    border: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(98, 41, 255);
    color: rgb(202, 202, 202);
    width: 100%;
    height: 70px;
    justify-content: left;
    padding-left: 50px;
    margin:auto;
}

.add-entry__text{
    position: relative;
    padding-left: 20px;
    bottom: 1.5px;
}
.add-entry:hover, .add-entry:active{
    background-color: rgb(99, 89, 241);
    color: white;
    cursor: pointer;
}


.floating-button{
    width: 56px;
    height: 56px;
    background: rgb(39, 255, 208);
    border-radius: 50%;
    position: fixed;
    left:85%;
    top: 90%;
    z-index: 2000;
}


.floating-button__icon{
    position: absolute;
    top: 25%;
    left: 25%;
    margin: auto;
    height: 50%;
    width:50%;
}

.floating-button:hover, .floating-button:hover svg{
    background: rgb(0, 140, 255);
    color: white;
    cursor: pointer;
}




@media(max-width: 500px){
    .floating-button{
        width: 56px;
        height: 56px;
        background: rgb(39, 255, 208);
        border-radius: 50%;
        position: fixed;
        left: 80%;
        bottom: 5%;
    }
}
@media(max-width: 500px){
    .container{
        width: 100%;
        margin-bottom: 200px;
    }
}

