@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
html{
  background: url(/images/moon-2048727_1920.jpg) no-repeat center center fixed; 
  background-size: 100% 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
    box-sizing: border-box;

  
}

#root{
  height: 100%;
}
/* Style the accordion section */
.AccordEntry_accordion__kb0nV{
    display: flex;
    flex-direction: column;
  }

/* Style the buttons that are used to open and close the accordion panel */
.AccordEntry_accordion__header__1XfLQ{
  display:grid;
  grid-auto-flow: row;
  grid-template-areas: 'item1 item2 item3 item4';
  grid-template-columns: 20px .5fr 8.5fr 1fr;
  background-color: rgb(153, 0, 255);
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 18px;
  border: none;
  outline: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1000;
}


@media(max-width: 500px){
  .AccordEntry_accordion__header__1XfLQ{


    display:grid;
  
    grid-auto-flow: row;
    grid-template-areas: 'item1 item2 item3 item4';
    grid-template-columns: 15px .5fr 8.5fr 1fr;
    background-color: rgb(153, 0, 255);
    align-items: center;
    color: white;
    cursor: pointer;
    padding: 18px;
    border: none;
    outline: none;
  }
}

/* Style to rotate icon when state is active */
.AccordEntry_rotate__3GqrN {
  grid-area: item1;
  transform: rotate(90deg);
  
}

.AccordEntry_accordion__header__edit__SYyU0{
  grid-area: item2;
  background-color: inherit;
}


/* Style the accordion content title */
.AccordEntry_accordion__header__text__3T2DW {
  grid-area: item3;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AccordEntry_accordion__header__text__3T2DW input{
  width: 100%;
  display: inline-block;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-bottom: rgb(39, 255, 208) solid 2px;
  background-color: inherit;
  font: inherit;
  color: inherit;
}
.AccordEntry_accordion__header__delete__3PmG_{
    grid-area: item4;
    background-color: inherit;
    justify-self: center;
}



/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.AccordEntry_accordion__header__1XfLQ:hover, .AccordEntry_active__2lE3l {
  background-color: rgb(133, 39, 255);
}

.AccordEntry_accordion__header__1XfLQ:hover svg{
  color: white;
}

/* Style the accordion chevron icon */
.AccordEntry_accordion__icon__1Z3DV {
  /* margin-left: auto;
  transition: transform 0.6s ease; */
}


/* Style the accordion content panel. Note: hidden by default */
.AccordEntry_accordion__content__2XUgQ {
  background-color: rgb(36, 36, 36);
  color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
  padding-left: 25px;
  padding-right: 25px;
  word-wrap: break-word
}



.AccordEntry_editor__1u3p-{
  background: black;
  word-wrap: break-word
}
body{
    /* background-color: rgb(28, 28, 28); */
    height: 100%;
}
.AccordFile_container__1ZWkA{
    width: 70%;
    margin: 5% 0;
    position: relative; 
    left: 20%;
    

}

@media(max-width: 500px){
    .AccordFile_container__1ZWkA{
        left: 0;
    }
}

.AccordFile_isEmpty__2JLWR{
    font-size: 2rem;
}
.AccordFile_back__JHDtr{
    color: white !important;
}



.AccordFile_file-name__1D-q1{
    font-size: 2rem;
    color: white;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media(max-width: 500px){
    .AccordFile_file-name__1D-q1{
        font-size: 1.3rem;
        text-overflow: clip;
    }
}
.AccordFile_file-name__1D-q1 svg{
    color: white;
}
.AccordFile_file-name__1D-q1 input{
    font: inherit;
    color: inherit;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: rgb(39, 255, 208);
    width: 90%;
    background: inherit;
}
.AccordFile_add-entry__WTs_b{
    display: flex;
    font-size: 1.4rem;
    padding-top: 20px;
    border: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(98, 41, 255);
    color: rgb(202, 202, 202);
    width: 100%;
    height: 70px;
    justify-content: left;
    padding-left: 50px;
    margin:auto;
}

.AccordFile_add-entry__text__1YS0c{
    position: relative;
    padding-left: 20px;
    bottom: 1.5px;
}
.AccordFile_add-entry__WTs_b:hover, .AccordFile_add-entry__WTs_b:active{
    background-color: rgb(99, 89, 241);
    color: white;
    cursor: pointer;
}


.AccordFile_floating-button__7vBUg{
    width: 56px;
    height: 56px;
    background: rgb(39, 255, 208);
    border-radius: 50%;
    position: fixed;
    left:85%;
    top: 90%;
    z-index: 2000;
}


.AccordFile_floating-button__icon__1XHOa{
    position: absolute;
    top: 25%;
    left: 25%;
    margin: auto;
    height: 50%;
    width:50%;
}

.AccordFile_floating-button__7vBUg:hover, .AccordFile_floating-button__7vBUg:hover svg{
    background: rgb(0, 140, 255);
    color: white;
    cursor: pointer;
}




@media(max-width: 500px){
    .AccordFile_floating-button__7vBUg{
        width: 56px;
        height: 56px;
        background: rgb(39, 255, 208);
        border-radius: 50%;
        position: fixed;
        left: 80%;
        bottom: 5%;
    }
}
@media(max-width: 500px){
    .AccordFile_container__1ZWkA{
        width: 100%;
        margin-bottom: 200px;
    }
}


.SideNav_side-nav__3Z6wa{
    position:fixed;
    background: rgb(125, 53, 241);
    width: 15%;
    height: 100%;
    bottom: 0px;
    z-index: 1500;
    min-width: 200px;
    padding: 50px 0 0 0;
    font-size: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left:0;
    top:0;
}


.SideNav_side-nav__file__PlJji{
    width: 100%;
}
.SideNav_side-nav__file__PlJji:hover{
    background: rgb(36, 147, 238);
}

.SideNav_side-nav__user__3XdrA{
    width: 100%;
}

.SideNav_side-nav__user__3XdrA:hover{
    background: rgb(36, 147, 238);
}


.SideNav_side-nav__envelop__24w6s{
    width: 100%;
}


.SideNav_side-nav__envelope__grNXm:hover{
    background: rgb(36, 147, 238);
}

.SideNav_side-nav__3Z6wa a{
    text-decoration: none;
    color: white;
    outline: none;
    padding-left: 5%;
}

.SideNav_side-nav__logout__12-S4{
    padding-left: 5%;
}
.SideNav_side-nav--selected__FSAl8{
    display: flex; 
    width: 100%;
    background: rgb(130, 36, 238);
}



.SideNav_side-nav__3Z6wa svg{
    width: 20px;
    margin: 0px 10px;
}




.SideNav_side-nav__file__PlJji:hover{
    background: rgb(36, 147, 238);
}

.SideNav_side-nav__logout__12-S4{
    display:flex;
}
.SideNav_side-nav__logout__button__2RSCf{
    display: flex;
    color: white;
    font-size: 2rem;
    width: 100%;
    height: 50px;
    background: none;
    padding: 0;
    border: none;
}
.SideNav_side-nav__logout__12-S4:hover{
    background: rgb(36, 147, 238);
    cursor: pointer;
}
.SideNav_side-nav__logout__12-S4 button{
    color:white;
    
}

.SideNav_side-nav__logout__12-S4 button{
    cursor: pointer;
}
.SideNav_side-nav__logout__12-S4 svg{
    width: 30px;
    margin-bottom: 5px;
}

/* for hamburger icon */

.SideNav_hamburger__3qfhe{
    position: fixed;
    z-index: 2000;
    top: 0;
    left:0;
}
.SideNav_hamburger-icon__1WS7p{
    background: rgb(125, 53, 241);
    border-radius: 5px;
    color: rgb(220, 220, 220);
}
.SideNav_hamburger-icon__1WS7p svg{
    color: white;
}

.FileName_single-entry__2lifb{
    display:grid;
    grid-template-columns: 6fr 1fr;
    grid-template-areas: 'item1 item2';
    justify-items: flex-start;
    grid-auto-flow: column;
    border-radius: 30px;
    padding: 0px 5px;
}

@media(max-width: 500px){
    .FileName_single-entry__2lifb{
        display:grid;
        grid-template-columns: 5fr 2fr;
        grid-template-areas: 'item1 item2';
        justify-items: flex-start;
        grid-auto-flow: column;
        border-radius: 30px;
        padding: 0px 5px;
    
    }
}

.FileName_single-entry__2lifb:hover, .FileName_single-entry__2lifb:active, .FileName_single-entry__2lifb:hover a, .FileName_single-entry__2lifb:hover svg{
    background-color:rgb(153, 0, 255);
    color: white;
    text-decoration: underline;

}
.FileName_content__2JTQc{
    grid-area: item1;
    width: 100%;
}

.FileName_content__link__1m_ZR{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media(max-width: 500px){
    .FileName_content__2JTQc{
        min-width: 300px;
    }
    .FileName_content__link__1m_ZR{
        display: inline-block;
        width:200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
}

.FileName_trash__1Zu-3{
    grid-area: item2;
    justify-self: end;
}

.FileName_rename-bar__3h4nH {
    width: 80%;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom:2px solid rgb(49, 184, 246);
    background: inherit;
    color: inherit;
    font: inherit;
}

.FileName_rename-bar__3h4nH:hover{
    color: inherit;
}


.FileName_toggle__rOgyb{
    width: 100%;
    position: relative;
}

.FileName_test__2xGJc{
    width: 100%;
}




.FileSelection_file-list__2eFwv{
    list-style: none;
    display: flex;
    flex-direction:column ;
    /* border: 2px solid black; */
    width: 40%;
    margin: 100px auto;
    min-width: 598px;
    position: relative;

}

.FileSelection_file-list__2eFwv a{
    /* color: rgb(177, 70, 248); */
    color: white;
    text-decoration: none;
}
.FileSelection_file-list__2eFwv svg{
    /* color: rgb(153, 0, 255) */
   /* color: rgb(20, 20, 20) */
   /* color: rgb(206, 206, 206); */
   color: rgb(194, 194, 194);

}

@media(max-width: 500px){
    .FileSelection_file-list__2eFwv{
        list-style: none;
        display: flex;
        flex-direction:column ;
        /* border: 2px solid black; */
        width: 100%;
        margin: 100px 0;
        min-width: 300px;
    }
}

/* .file-list__items{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid black;

} */


/* .file-list__items:hover, .file-list__items:active, .file-list__items:focus{
    background: rgb(153, 0, 255);
} */
/* .file-list__items--trash{
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
} */
.FileSelection_add-button__3Rccm{
    display: flex;
    width: 100%;
    height: 50px;
    background-color: rgb(133, 39, 255);
    color: white;
    border: none;
    justify-content: left;
    padding-left: 5px;
    align-items: center;
    /* margin-left: 35%; */
    border-radius: 30px;
    cursor: pointer;
}

.FileSelection_add-button__3Rccm svg{
    color:rgb(226, 226, 226);
}

.FileSelection_add-button__3Rccm:hover, .FileSelection_add-button__3Rccm:active, .FileSelection_add-button__3Rccm:hover svg{

    color: white;
    background-color:rgb(153, 0, 255);
    text-decoration: underline;
}

.FileSelection_search__sulfl{
    color: white;
    background-color: rgb(39, 39, 39);
    border-radius: 100px;
    padding: 5px;
    margin: 20px 0px;
    display: flex;
    align-content: center;
    border: solid 3px rgb(48, 14, 134);
    
}

.FileSelection_search__sulfl:hover{
    border: solid 3px rgb(153, 0, 255);
}
.FileSelection_search__bar__1g9dv{
    width: 80%;
    outline: none;
    border: none;
    background: inherit;
    color: inherit;
    padding-left: 10px
}

.FileSelection_search__icon__3fwye{
    height: 40px;
    width: 40px;
    border: 2px;
    border-radius: 50%;
    /* padding: 20px; */
    background: rgb(153, 0, 255);
    position: relative;
    display: flex;
    justify-content: center;
}

.FileSelection_search__icon__3fwye img{
    height: 50%;
    position: absolute;
    top: 10px;
    color: white;
}

.FileSelection_search__icon__3fwye:hover{
    cursor:pointer;
    background-color:rgb(181, 71, 255);
}

.FileSelection_floating-button__2Ovho{

    width: 56px;
    height: 56px;
    background: rgb(39, 255, 208);
    border-radius: 50%;
    position: fixed;
    left:85%;
    top: 90%;
}
@media(max-width: 500px){
    .FileSelection_floating-button__2Ovho{

        width: 56px;
        height: 56px;
        background: rgb(39, 255, 208);
        border-radius: 50%;
        position: fixed;
        left: 80%;
        bottom: 5%;
    }
}
.FileSelection_floating-button__icon__Q8PV8{
    position: absolute;
    top: 25%;
    left: 25%;
    margin: auto;
    height: 50%;
    width:50%;
}

.FileSelection_floating-button__2Ovho:hover, .FileSelection_floating-button__2Ovho:hover svg{
    background: rgb(0, 140, 255);
    color: white;
    cursor: pointer;
}

.FileSelection_file-list__array__okBEj{
    /* position: relative; */
}
.FileSelection_shade__3oeHw{
    height:100%;
    width: 100%;
    opacity: 0.5;
    background: black;
    /* border-top: 3px solid #ccc;
    border-bottom: 3px solid #ccc; */
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    z-index: -200;
}

.Signup_signup__NsQ-m{
    margin: 10% auto;
    display: grid;
    width: 25%;
    padding: 25px;
    min-width: 500px;
    background: white;
    border-radius: 2px;
    color: rgb(98, 51, 226);
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
    grid-template-areas: 
    'title'
    'email'
    'password'
    'password-confirmation'
    'submit';

}

.Signup_signup__title__1k-ng{
    grid-area: title;
    justify-self: center;
    color: rgb(115, 10, 235);
    margin: 0;

}
.Signup_signup__title__1k-ng h1{
    margin: 10px;
}
.Signup_signup__title__1k-ng span{
    display: flex;
    margin: 0px 40px;
}

.Signup_signup__email__2Kt9T{
    grid-area: email;
    margin: 10px 0;
}


/* just to line up the label with the svg */
.Signup_signup__email__2Kt9T label{
    position: relative;
    bottom: 5px;
}
.Signup_signup__email__2Kt9T input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}

/* shine on click */
.Signup_signup__email__2Kt9T:focus-within{
    color:rgb(163, 50, 255)
}

.Signup_signup__email__2Kt9T input:focus{
    border: rgb(163, 50, 255) solid 2px;
}

.Signup_signup__password__303RW{
    grid-area: password;
    margin-top: 20px;
}

.Signup_signup__password__303RW input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}

.Signup_signup__password__303RW:focus-within{
    color: rgb(163, 50, 255);
}
.Signup_signup__password__303RW input:focus{
    border: rgb(163, 50, 255) solid 2px;
}


.Signup_signup__password-confirmation__3FaY8 input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}
/* Password glow on click */
.Signup_signup__password-confirmation__3FaY8:focus-within{
    color: rgb(163, 50, 255);
}
.Signup_signup__password-confirmation__3FaY8 input:focus{
    border: rgb(163, 50, 255) solid 2px;
}
.Signup_signup__password-confirmation__3FaY8{
    grid-area: password-confirmation;
    margin-top: 20px;
}


/* Submit button */
.Signup_signup__submit__2lTUb{
    grid-area: submit;
    padding: 20px 0;
}

.Signup_signup__submit__2lTUb button{
    width: 100%;
    border-radius: 5px;
    border: none;
    height: 50px;
    background: linear-gradient(to right, #4776e6, #8e54e9);
    color: white;

}

/* Button glow */
.Signup_signup__submit__2lTUb:hover button{
    background: linear-gradient(to right, #00f260, #0575e6);
    cursor:pointer;
}



/* Make full width and have space on mobile */

@media(max-width: 500px){
    .Signup_signup__NsQ-m{
        margin: 20% auto;
        display: grid;
        width: 100%;
        border: black solid 2px;
        padding: 25px;
        min-width: 325px;
    }
    
}
.Signup_error__1O8b3{
    color: red;
    border: red solid 2px;
    width: 100%;
    height: 100%;
    padding: 10px;
}
.Login_container__3OieE{
    width: 70%;
    height: 100%;
    margin: auto;
    background: rgba(0, 0, 0, 0.5);
    min-width: 475px;
}

@media(max-width: 500px){
    .Login_container__3OieE{
        width: 100%;
    }
}
.Login_login__3If6u{
    margin: 10% auto ;
    display: grid;
    width: 25%;
    padding: 25px;
    min-width: 475px;
    background: rgb(255, 255, 255);
    border-radius: 2px;
    color: rgb(98, 51, 226);

    grid-template-areas: 
    'title'
    'email'
    'password'
    'submit'
    'signup'
    'forgot';
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
}


.Login_login__title__1jUBE{
    grid-area: title;
    justify-self: center;
    color: rgb(115, 10, 235);
}


.Login_login__email__27LaC{
    grid-area: email;
    margin: 10px 0;
}

.Login_login__email__27LaC:focus-within{
    color: rgb(163, 50, 255);
}

.Login_login__email__27LaC label{
    position: relative;
    bottom: 5px;
}
.Login_login__email__27LaC input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}

.Login_login__email__27LaC input:focus{
    border: rgb(163, 50, 255) solid 2px;
}

.Login_login__password__2P-D2{
    grid-area: password;
    margin-top: 20px;
}
.Login_login__password__2P-D2 label{
    position: relative;
    bottom: 3px;
}

.Login_login__password__2P-D2 input{
    width: 100%;
    height: 2.1rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: solid 1px black;
    outline: none;
    padding-left: 5px;
}

.Login_login__password__2P-D2:focus-within{
    color: rgb(163, 50, 255);
}
.Login_login__password__2P-D2 input:focus{
    border: rgb(163, 50, 255) solid 2px;
}

.Login_login__submit__2C8-5{
    grid-area: submit;
    padding: 20px 0;
}
.Login_login__submit__2C8-5 button{
    width: 100%;
    border-radius: 5px;
    border: none;
    height: 50px;
    background: linear-gradient(to right, #4776e6, #8e54e9);
    color: white;

}

.Login_login__submit__2C8-5:hover button{
    background: linear-gradient(to right, #00f260, #0575e6);
    cursor: pointer;
}
.Login_login__signup__3kXLl{
    grid-area: signup;
    justify-self: center;

}



.Login_login__forgot__2HAfv{
    grid-area: forgot;
    justify-self: center;
}



@media(max-width: 1450px){
    .Login_container__3OieE{
        width: 100%;
    }
}

@media(max-width: 500px){
    .Login_login__3If6u{
        display: grid;
        width: 100%;
        border: black solid 2px;
        padding: 25px;
        min-width: 300px;
        left: 0%;
    }
    .Login_container__3OieE{
        min-width: 300px;
    }
    
}


.Login_error__2fUyU{
    color: red;
    border: red solid 2px;
    width: 100%;
    height: 100%;
    padding: 10px;
}


.Back_back__wMS5K{
    width: 50px;
    color: rgb(130, 38, 252);
    border-radius: 50%;
    position: relative;
    top: 125px;
    left: 20%;

}

.Back_back__wMS5K:hover{
    cursor: pointer;
    
}

.Back_back__wMS5K svg:hover{
    color:rgb(165, 55, 255)
}


.TopNav_top-nav__16VTf{
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 1fr;
    background-color: rgb(154, 30, 255);
    height: 100%;
    max-height: 65px;
    position:relative;
}   



/* logo */
.TopNav_top-nav__left__2mDqp{
    grid-area: left;
    display: flex;
    justify-content: flex-start;
}
.TopNav_top-nav__logo__WM65g{
    display: grid;
    align-content: center;
    margin-left: 30px;
    height: 100%;
    max-height: 65px;
}

.TopNav_top-nav__logo__WM65g h1{
    max-height: 65px;
    height: 100%;
}



/* Nav options */
.TopNav_top-nav__right__3A7Xs{
    grid-area: right;
    display: flex; 
    justify-content: space-evenly;
}



/* Undo a tag styles */
.TopNav_top-nav__16VTf a{
    text-decoration: none;
    color: white;
    outline: none;
}




.TopNav_top-nav__home__2ituF{
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100%;
    max-height: 65px;
    width: 15%;
    position: relative;
}

.TopNav_top-nav__home__2ituF a{
    padding-right: 4px;
}

.TopNav_top-nav__home__2ituF:hover{
    border-bottom: 5px solid rgb(0, 255, 179);
    color:rgb(0, 255, 179);
    padding-top: 5px;
}

.TopNav_top-nav__home__2ituF a:hover{
    color:rgb(0, 255, 179)
}


.TopNav_top-nav__contact__3F3p-{
    position: relative;
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100%;
    max-height: 65px;
    width: 15%;
}


.TopNav_top-nav__contact__3F3p-:hover{
    border-bottom: 5px solid rgb(0, 255, 179);
    color:rgb(0, 255, 179);
    padding-top: 5px;
}

.TopNav_top-nav__contact__3F3p- a:hover{
    color:rgb(0, 255, 179)
}
.TopNav_top-nav__login__1p16b{
    position: relative;
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100%;
    max-height: 65px;
    width: 30%;
    background-color: rgb(57, 54, 248);
}


.TopNav_top-nav__login__1p16b:hover{
    background: rgb(89, 0, 255);
    border-bottom: 5px solid rgb(0, 255, 179);
    padding-top: 5px;
}

.TopNav_top-nav__login__1p16b a:hover{
    color:rgb(0, 255, 179)
}

/* active link react router */
.TopNav_top-nav--selected__2gmNm{
     display: grid; 
    justify-content: center;
    align-content: center; 
    position: absolute;
    border-bottom: 5px solid rgb(91, 129, 255);
    padding-top: 5px;
    width: 100%;
    height: 100%;
}

/* Side Nav content */
.TopNav_side-nav__2Q56l{
    position:fixed;
    display: flex;
    flex-direction: column ;
    background: rgb(125, 53, 241);
    width: 15%;
    height: 100%;
    z-index: 100;
    min-width: 300px;
    padding: 50px 0 0 0;
}


/* for hamburger icon */
.TopNav_hamburger-icon__1HIDZ{
    background: rgb(107, 20, 220);
    border-radius: 10px;
}
.TopNav_hamburger-icon__1HIDZ svg{
    color: white;
}

.TopNav_contact__3fnu5 a{
    color: white;
}

.PreSideNav_spacer__1RvtO{
    margin-bottom: 25px;
}

.PreSideNav_side-nav__closed__1U-B2{
    width: 100%;
    height: 65px;
    background: rgb(154, 30, 255);
    position: fixed;
    z-index: 300;
    top:0;
    right:0;
    /* margin-bottom: 500px; */
}

.PreSideNav_side-nav__opened__23e9X{
    position: fixed;
    height: 100%;
    z-index: 400;
    top: 0;
    right: 0;
}

.PreSideNav_side-nav__toggle__3gvHL{
    position: fixed;
    z-index: 700;
    top: 0;
    left: 0;
}


.PreSideNav_side-nav__content__1qyIH{
    display:flex;
    position: fixed;
    flex-direction: column;
    height: 100vh;
    width: 300px;
    background: rgb(154, 30, 255);
    justify-content: normal;
    z-index: 600;
    top: 0;
    left: 0;
    padding-top: 80px;
}

.PreSideNav_side-nav__content__1qyIH a{
    color: white;
    text-decoration: none;
    width: 100%;
    font-size: 2rem;
    z-index: 600;
    padding-bottom: 2px;
    display: flex;
    outline: none;
}

.PreSideNav_side-nav__content__1qyIH a:hover{
    background: rgb(36, 147, 238);

}

.PreSideNav_side-nav__content--selected__2g6_D{
    display: flex;
    width: 100%;
    background: rgb(130, 36, 238);
}
.PreSideNav_side-nav__content__1qyIH svg{
    width: 20px;
    margin: 0 10px;
}

.PreSideNav_side-nav__content__home__RpDnc{
    width: 100%;
}
.PreSideNav_side-nav__content__home__RpDnc span{
    position: relative;
    bottom: 1px;
}
.PreSideNav_side-nav__content__contact__39nxK span{
    position: relative;
    bottom: 2px;
}
.PreSideNav_side-nav__content__login__1lAd8 span{
    position: relative;
    bottom: 1px;
}


.PreSideNav_logo__2gXl1 a{
    text-decoration: none;
    color: inherit;
}

body{
    height:100%;
    width: 100%;
    /* background-size: 100%;
    background: url(/images/moon-2048727_1920.jpg) no-repeat center center fixed;  */
    /* background-color: rgb(43, 37, 37); */
}

.HomePage_presentation__2Nier{
    display: grid;
    grid-template-areas: 
    'navbar navbar navbar navbar'
    'top top top top'
    'demo demo demo demo';
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-column: auto;
    width: 70%;
    margin: auto;
}


.HomePage_navbar__2RnUb{
    grid-area: navbar;
}

.HomePage_top__1TQUi{
    grid-area: top;
    display: flex;
    flex-wrap: wrap;
    margin: 10% 0;
}
.HomePage_video__3vBxQ{
    grid-area: video;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%; 
    position: relative;
    padding-bottom: 56.25%;
    z-index: 100;
}
.HomePage_video__3vBxQ iframe{
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.HomePage_reasons__Yi-kx{
    grid-area: reasons;
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: center;
    min-width: 300px;
}

.HomePage_reasons__item__325rC{
    position: relative;
}

.HomePage_reasons__item__title__2qbxb{
    font-size: 1.75rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.HomePage_reason__item__description__3o0IF{
    text-align: center;
    font-size: 1.4rem;
}

.HomePage_demo__1s8jk{
    grid-area: demo;
}

.HomePage_demo__1s8jk h1{
    text-align: center;
}


.HomePage_shade__kWsBT{
    height:100%;
    margin-bottom: 90px;
    width: 100%;
    opacity: 0.5;
    background: black;
    border-radius: 3px;
    margin-top: 5.0em;
    position: relative;
    bottom: 750px;
    bottom: 100%;
    z-index: -200;
}

@media(max-width: 500px){
    .HomePage_presentation__2Nier{
        width: 100%;
    }
    .HomePage_video__3vBxQ{
        width: 100%;
    }
}


@media(max-width: 1450px){
    .HomePage_presentation__2Nier{
        width: 100%;
    }
}

@media(max-width: 1000px){

    .HomePage_video__3vBxQ{
        width: 100%;
    }
    .HomePage_reasons__Yi-kx{
        width: 100%;
    }

}



body{
    height: 100%;
}
.DemoAccordion_container__A9Fks{
    width: 100%;
 } 

.DemoAccordion_isEmpty__18Axy{
    font-size: 2rem;
    text-align: center;
}
.DemoAccordion_back__2QifW{
    color: white !important;
}

.DemoAccordion_file-name__1_1se{
    font-size: 2rem;
    color: white;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media(max-width: 500px){
    .DemoAccordion_file-name__1_1se{
        font-size: 1.3rem;
        text-overflow: clip;
    }
}
.DemoAccordion_file-name__1_1se svg{
    color: white;

}
.DemoAccordion_file-name__1_1se input{
    font: inherit;
    color: inherit;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: rgb(39, 255, 208);
    width: 90%;
    background: inherit;
}
.DemoAccordion_add-entry__3yyV3{
    display: flex;
    font-size: 1.4rem;
    padding-top: 20px;
    border: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(98, 41, 255);
    color: rgb(202, 202, 202);
    width: 100%;
    height: 70px;
    justify-content: left;
    padding-left: 50px;
    margin:auto;
}

.DemoAccordion_add-entry__text__1ee6g{
    position: relative;
    padding-left: 20px;
    bottom: 1.5px;
}
.DemoAccordion_add-entry__3yyV3:hover, .DemoAccordion_add-entry__3yyV3:active{
    background-color: rgb(99, 89, 241);
    color: white;
    cursor: pointer;
}


.DemoAccordion_floating-button__1k-bx{
    width: 56px;
    height: 56px;
    background: rgb(39, 255, 208);
    border-radius: 50%;
    position: fixed;
    left:85%;
    top: 90%;
}


.DemoAccordion_floating-button__icon__1eHPZ{
    position: absolute;
    top: 25%;
    left: 25%;
    margin: auto;
    height: 50%;
    width:50%;
}

.DemoAccordion_floating-button__1k-bx:hover, .DemoAccordion_floating-button__1k-bx:hover svg{
    background: rgb(0, 140, 255);
    color: white;
    cursor: pointer;
}

@media(max-width: 500px){
    .DemoAccordion_floating-button__1k-bx{
        width: 56px;
        height: 56px;
        background: rgb(39, 255, 208);
        border-radius: 50%;
        position: fixed;
        left: 80%;
        bottom: 5%;
    }
}
@media(max-width: 500px){
    .DemoAccordion_container__A9Fks{
        width: 100%;
        margin-bottom: 200px;
    }
}

/* Style the accordion section */
.DemoAccordion_accordion__8Yiaf{
    display: flex;
    flex-direction: column;
  }

/* Style the buttons that are used to open and close the accordion panel */
.DemoAccordion_accordion__header__3s3XJ{


  display:grid;

  grid-auto-flow: row;
  grid-template-areas: 'item1 item2 item3 item4';
  grid-template-columns: 20px .5fr 8.5fr 1fr;
  background-color: rgb(153, 0, 255);
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 18px;
  border: none;
  outline: none;
}


@media(max-width: 500px){
  .DemoAccordion_accordion__header__3s3XJ{


    display:grid;
  
    grid-auto-flow: row;
    grid-template-areas: 'item1 item2 item3 item4';
    grid-template-columns: 15px .5fr 8.5fr 1fr;
    background-color: rgb(153, 0, 255);
    align-items: center;
    color: white;
    cursor: pointer;
    padding: 18px;
    /* display: flex; */
    /* align-items: center; */
    border: none;
    outline: none;
    /* transition: background-color 0.6s ease; */
  }
}

/* Style to rotate icon when state is active */
.DemoAccordion_rotate__2I41y {
  grid-area: item1;
  transform: rotate(90deg);
  
}

.DemoAccordion_accordion__header__edit__39zmI{
  grid-area: item2;
  background-color: inherit;
}


/* Style the accordion content title */
.DemoAccordion_accordion__header__text__28Tos {
  grid-area: item3;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DemoAccordion_accordion__header__text__28Tos input{
  width: 100%;
  display: inline-block;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-bottom: rgb(39, 255, 208) solid 2px;
  background-color: inherit;
  font: inherit;
  color: inherit;
}
.DemoAccordion_accordion__header__delete__kvoUS{
    grid-area: item4;
    background-color: inherit;
    justify-self: center;
}



/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.DemoAccordion_accordion__header__3s3XJ:hover, .DemoAccordion_active__jHcxU {
  background-color: rgb(133, 39, 255);
}

.DemoAccordion_accordion__header__3s3XJ:hover svg{
  color: white;
}

/* Style the accordion content panel. Note: hidden by default */
.DemoAccordion_accordion__content__1q6FR {
  background-color: rgb(36, 36, 36);
  color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
  padding-left: 25px;
  padding-right: 25px;
  word-wrap: break-word

}



.DemoAccordion_editor__1JP9s{
  background: black;
  word-wrap: break-word
}

html{
    height: 100%;
}
body{
    height: 100%;
}
.Contact_container__2qsA2{
    /* width: 100%; */
    width: 70%;
    margin: auto;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    min-width: 250px;


}
.Contact_contact__UhH7p{
    width: 20%;
    min-width: 250px;
    height: 20%;
    margin: auto;
    padding-top: 10%;
    color: white;
    text-align: center;
}
.Contact_container__2qsA2 a{
    color:white
}


@media(max-width: 1450px){
    .Contact_container__2qsA2{
        width: 100%;
    }
}

@media(max-width: 1000px){   
    .Contact_contact__UhH7p{
        width: 100%;
        padding-top: 20%;
    }
}
